<template>
    <div id="container">
        <div id="contents">
            <div class="contents_header">               
            </div>
			<div class="contents_body">
				<div class="Board">
					<table class="Board_type3">
						<colgroup>
							<col width="150"/>
							<col width="*"/>
						</colgroup>
						<tbody>	
							<tr>
								<th>제목</th>
								<td><input type="text" name="" id="nttSj" class="" v-model="data.nttSj" style="width:500px"></td>
							</tr>
							<tr>
								<th>공지글 지정</th>
								<td>
                                    <input type="checkbox" name="" id="chk11" class="admchk" 
                                        v-model="data.noticeAt" 
                                        true-value="Y" 
                                        false-value="N">
                                    <label for="chk11"></label>
                                </td>
							</tr>
							<tr>
								<td colspan="2" class="content">
									<textarea class="txtarea" id="nttCn" name="nttCn" rows="5" cols="100" 
                                        v-model="data.nttCn" style="width:100%;height:400px">
                                    </textarea>
								</td>
							</tr>
							
							<!-- 게시물 등록일때 첨부파일 2개 -->
                            <!-- 첨부파일은 나중에 -->
                            <template v-if="isReg">
							<tr id="fileUpload" v-for="index in 2" :key="index">
								<th>첨부파일 {{index}}</th>
								<td>
									<input class="upload-name" type="file" name="uploadFile" ref="ref_uploadFile" @change="attaFileTypeCheck" 
										accept="image/*, .pdf, .hwp, .xls, .xlsx, .doc, .docx, .ppt, .pptx"
									/>
								</td>
							</tr>
                            </template>
							<!-- // -->
							
							<!-- 게시물 수정일때 -->
                            <!-- 첨부파일은 나중에 -->
                            <template v-if="!isReg">
							<tr v-for="(item, index) in data.attaList" :key="item.attaFileUniqHash">
								<th>첨부파일 {{index+1}}</th>
								<td>
									<span class="filename" @click="attaDownload(item.attaFileUniqHash)">{{item.orgFileNm }}</span>
									<span class="del" @click="deleteFileRow(index)"><img src="/images/board/btn_delete_s.png" alt="삭제"></span>
								</td>
							</tr>
							<tr id="fileUpload"
								v-for="index in (2-data.attaList.length)" :key="index">
								<th>첨부파일 {{index}}</th>
								<td>
									<input class="upload-name" type="file" name="uploadFile" ref="ref_uploadFile" @change="attaFileTypeCheck" 
										accept="image/*, .pdf, .hwp, .xls, .xlsx, .doc, .docx, .ppt, .pptx"
									/>
								</td>
							</tr>
                            </template>
							<!-- // -->
							
							<tr v-if="!isReg">
								<th>삭제여부</th>
								<td>
									<div class="select_wrap">
										<select id="" name="" v-model="data.delYn" true-value="Y" false-value="N"> 
											<option>Y</option>
											<option>N</option>											
										</select>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				
				<!--버튼영역-->
				<div class="BtnArea">
					<div class="Btninner">								
                        <span v-if="isReg" class="btn write" @click="btnReg">등록</span>
                        <span v-if="!isReg" class="btn write" @click="btnMod">수정</span>
                        <span class="btn list" @click="btnCancel">취소</span>
					</div>
				</div>
				<!--//버튼영역-->
			</div><!--//contents_body-->
        </div><!--//contents-->
    </div><!--//container-->
</template>

<script>

export default {
    data() {
        return {
            data : {  
                noticeAt : 'N',
                attaList : []
            },

            files : [],

            isReg : true,
            noticeAt : 'N',
        }
    },

    beforeCreate() {
      // console.log('beforeCreate');
    },
    created() {
      // console.log('created');
    },
    beforeMount() {
      // console.log('beforeMount');
    },
    mounted() {
      // console.log('mounted');
      this.getDetail();
    },
    beforeUpdate() {
      // console.log('beforeUpdate');
    },
    updated() {
    //   console.log('update');
    },
    beforeDestroy() {
      // console.log('beforeDestroy');
    },
    destroyed() {
      // console.log('destroyed');
    },    

    methods : {

        toggleCheckbox() {
            if(this.data.noticeAt != undefined && this.data.noticeAt == 'Y') {
                this.data.noticeAt = 'N';
            } else {
                this.data.noticeAt = 'Y';
            }
            // console.log("this.data.noticeAt : " + this.data.noticeAt);
        },
        
        getDetail() {

            // 수정일때만 상세 조회
            if(this.$route.query.nttId != null && this.$route.query.nttId != '') {
                
                this.isReg = false;

                var param = {
                    bbsId : this.$route.query.bbsId,
                    nttId : this.$route.query.nttId,
                };

                // console.log("param", param);
                this.$.httpPost('/api/bbs/getBbs', param)
                    .then(res => {
                        // console.log(res.data);
                        this.data = res.data;
                        
                    })
                    .catch(err => {
                        // console.log('============================='); 
                        // console.log(err);
                        // console.log(err.response);
                        // console.log('============================='); 

                        alert(err.response.data.error_description);
                    });
            }
        },

        btnCancel() {
            this.$router.go(-1);
        },

        setFiles() {
            for(var item of this.$refs.ref_uploadFile) {
                if(item.files[0]) {
                    // console.log('file', item.files[0]);
                    // console.log('filename', item.files[0].name);
                    this.files.push(item.files[0]);
                }
            }
        },

        btnReg() {

            // console.log(this.data);

            this.setFiles();

            this.$.fileUpload('IMG_PDF_DOD', this.files, '/api/bbs/regBbs', this.data)
                .then(() => {
                    // console.log(res.data);
                    this.$router.push("/adm/ADMNOTICE01M01");
                })
                .catch(err => {
                    // console.log('============================='); 
                    // console.log(err);
                    // console.log(err.response);
                    // console.log('============================='); 

                    alert(err.response.data.error_description);
                });
        },

        btnMod() {
            // console.log(this.data);

            this.setFiles();

            this.$.fileUpload('IMG_PDF_DOC', this.files, '/api/bbs/modBbs', this.data)
                .then(() => {
                    // console.log(res.data);
                    this.$router.push("/adm/ADMNOTICE01M01");
                })
                .catch(err => {
                    // console.log('============================='); 
                    // console.log(err);
                    // console.log(err.response);
                    // console.log('============================='); 

                    alert(err.response.data.error_description);
                });
        },

        // attaFileTypeCheck(event) {
        //     // 파일체크
        //     //this.files.push(event.target.files[0]);
        //     // console.log(event);
        // },

        // attaDownload(hash) {
        //     // console.log('다운로드 : ' + hash);
        // },

        deleteFileRow(index) {
            // console.log('삭제 index : ' + index);

            this.data.attaList.splice(index, 1);

        }
    }

}
</script>
